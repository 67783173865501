import React, { Fragment } from "react";
import { ThemeProvider } from "styled-components";
import { Modal } from "@redq/reuse-modal";
import Sticky from "react-stickynode";
import { DrawerProvider } from "common/contexts/DrawerContext";
import { saasClassicTheme } from "common/theme/saasClassic";
import { ResetCSS } from "common/assets/css/style";
import {
  GlobalStyle,
  ContentWrapper,
} from "containers/SaasClassic/saasClassic.style";

import Navbar from "Containers/Navbar";

import "@redq/reuse-modal/es/index.css";
import Seo from "components/seo";
import { graphql, StaticQuery } from "gatsby";

import Footer from "Containers/Footer";
import { proceedPayment } from "../../common/components/payment";

const SaasClassic = ({ location }) => {
  const [Tour, setTour] = React.useState();
  const [paymeURL, setPaymeURL] = React.useState();
  const [source, setSource] = React.useState("Dguide");

  React.useEffect(() => {
    const params = new URLSearchParams(location.search);

    let source_id = params.get("source");
    if (!source_id) {
      source_id = "Dguide";
    }

    setSource(source_id);

    async function getParams() {
      if (Tour /* && Tour.all_media */) {
        /* const pVideo = Tour.all_media.filter((media) =>
          media.type.includes("video")
        )[0];
        //      setProfileVideo(pVideo);
        const pPic = Tour.all_media.filter((media) =>
          media.type.includes("image")
        );
         */ //      setProfilePic(pPic);
        try {
          await proceedPayment(Tour, setPaymeURL, source);
        } catch (error) {
          console.log("ERROR", error);
        }
      }
    }
    getParams();
  }, [Tour]);
  return (
    <StaticQuery
      query={graphql`
        query shukSaleDPQuery {
          allTour(filter: { id: { eq: "lfngCB2AQGIoIdVkiIdu" } }) {
            nodes {
              id
              title
              hostedBy
              price
              language
            }
          }
        }
      `}
      render={(data) => {
        setTour(data.allTour.nodes[0]);
        if (!Tour) return null;

        return (
          <ThemeProvider theme={saasClassicTheme}>
            <Fragment>
              <Seo
                title={`Dguide`}
                description={"Dguide direct payment!"}
                lang={"he"}
                meta={[
                  "סיורים",
                  "סיורים בירושלים",
                  "טיול בירושלים",
                  "טיול",
                  "טיול למשפחה",
                  "מקומות לטייל בשבת",
                  "איפה אפשר לטייל",
                  "המלצות לטיולים",
                  "טיול יום",
                  "סיור יום",
                  "אטרקציות בירושלים",
                  "סיור עצמאי",
                  "מדריך טיולים",
                  "יום כיף",
                  "מה לעשות בירושלים",
                  "סיורים מודרכים בירושלים",
                ]}
                keywords={["סיור מודרך", "סיור עצמאי", `מה אפשר לעשות ב `]}
              />
              <Modal />
              <ResetCSS />
              <GlobalStyle />

              <ContentWrapper style={{ background: "#f9f9f9" }}>
                <Sticky top={0} innerZ={9999} activeClass="sticky-nav-active">
                  <DrawerProvider>
                    <Navbar returnPayment={true} />
                  </DrawerProvider>
                </Sticky>
                <div style={{ height: "770px" }}>
                  {paymeURL && (
                    <iframe
                      title="paymeWin"
                      id="paymeWin"
                      src={paymeURL}
                      style={{
                        marginTop: "100px",
                        width: "100%",
                        height: "670px",
                        position: "absolute",
                        overflowY: "scroll",
                      }}
                      allowFullScreen
                    />
                  )}
                </div>
                {/* <ScrollItems tours={Tours} /> */}
                <Footer />
              </ContentWrapper>
            </Fragment>
          </ThemeProvider>
        );
      }}
    />
  );
};
export default SaasClassic;
